<template>
    <div v-wechat-title="this.title">
        <van-sticky :offset-top="0">
            <div class="page_top">
                <div class="date_choose">
                    <i class="icon iconfont iconarrow-left-filling" @click="subtractDate"></i>
                    <span class="date_info" @click="chooseCheckDay">{{date || '请选择'}}</span>
                    <i class="icon iconfont iconarrow-right-filling" @click="addDate"></i>
                </div>
                <van-search class="search_div" input-align="center" background="#F8F7FC" @search="reLoad" v-model="keyword" placeholder="患者姓名或手机号" />
            </div>
        </van-sticky>


        <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false" @confirm="onConfirmCalendar" />

        <van-tabs
                v-model="qrystatus" :offset-top="62"
                @change="changeTab" class="status_div"
                color="#E69F12"
                title-active-color="#E69F12"
                sticky
                swipeable>

            <van-tab title="待执行" name="2" :badge="wait" >
                <div class="treat_main_list" :class="{'bai': count2 <= 0}" >
                    <van-list
                            v-model="loading2"
                            :finished="finished2"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list2.length>0"
                            finished-text="加载完成">
                        <div class="one"  v-for="item in list2" :key="item.serviceid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="detailCtrl(item.serviceid)">
                                        <span>查看详情</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">{{item.projectname}}</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">执行医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">医生备注</div>
                                <div class="right">{{item.doctorremark || '--'}}</div>
                            </div>
                            <div style="display: flex;justify-content: center;">
                                <div @click="$router.push({path:'/assignTreat',query:{
                                    userid:item.userid,customerid:item.customerid,name:item.name || '',sex:item.sex || '',age:item.age || '',phone:item.phone || ''
                                }})" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">追加分诊</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无理疗数据
                    </div>
                </div>
            </van-tab>

            <van-tab  title="执行中" name="3" :badge="execute">
                <div class="treat_main_list" :class="{'bai': count3 <= 0}">
                    <van-list
                            v-model="loading3"
                            :finished="finished3"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list3.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list3" :key="item.serviceid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="detailCtrl(item.serviceid)">
                                        <span>查看详情</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">{{item.projectname}}</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">执行医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">医生备注</div>
                                <div class="right">{{item.doctorremark || '--'}}</div>
                            </div>
                            <div style="display: flex;justify-content: center;">
                                <div @click="$router.push({path:'/assignTreat',query:{
                                    userid:item.userid,customerid:item.customerid,name:item.name || '',sex:item.sex || '',age:item.age || '',phone:item.phone || ''
                                }})" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">追加分诊</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无理疗数据
                    </div>
                </div>
            </van-tab>

            <van-tab  title="已完成" name="4" :badge="finish" >
                <div class="treat_main_list" :class="{'bai': count4 <= 0}">
                    <van-list
                            v-model="loading4"
                            :finished="finished4"
                            @load="onLoad"
                            :offset="30"
                            :immediate-check="false"
                            v-if="list4.length>0"
                            finished-text="加载完成">
                        <div class="one" v-for="item in list4" :key="item.serviceid">
                            <div class="name_appointment">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <div class="appoinment_num" v-if="item.appointmentnum">{{item.appointmentnum}}</div>
                                    <div class="detail_info" @click="detailCtrl(item.serviceid)">
                                        <span>查看详情</span>
                                        <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sex_age_phone">
                                <span>{{item.sex}} . {{item.age}}岁</span>
                                <span v-if="item.phone">{{item.phone}}</span>
                            </div>
                            <div class="title_top">
                                <div class="left">{{item.projectname}}</div>
                                <div class="right">{{item.appointmenttime}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">执行医生</div>
                                <div class="right">{{item.execdoctor}}</div>
                            </div>
                            <div class="title_top">
                                <div class="left">医生备注</div>
                                <div class="right">{{item.doctorremark || '--'}}</div>
                            </div>
                            <div style="display: flex;justify-content: center;">
                                <div @click="$router.push({path:'/assignTreat',query:{
                                    userid:item.userid,customerid:item.customerid,name:item.name || '',sex:item.sex || '',age:item.age || '',phone:item.phone || ''
                                }})" style="background-color: #CF8C57;width: 100px;height: 38px;text-align: center;line-height: 38px;color: #fff;border-radius: 21px;">追加分诊</div>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无理疗数据
                    </div>
                </div>
            </van-tab>
        </van-tabs>

        <div class="btnimg" @click="$router.push({path:'/triage'})">
            <img src="@/assets/btn.png" alt="">
        </div>
    </div>
</template>

<script>
    import { wxtreatstatistics,wxtreatqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    // import Vue from 'vue';
    import { Toast } from 'vant';
    import Vue from 'vue';
    import { Calendar } from 'vant';

    Vue.use(Calendar);

    export default {
        computed: {
            ...mapGetters([
                'username','clinicid','isrefresh','isclose'
            ]),
        },
        data() {
            return {
                qrystatus: '2',
                list2: [],
                // 无限加载
                loading2: false,
                finished2: false,
                count2: 0,
                page2: 1,
                isload2: false,
                list3: [],
                // 无限加载
                loading3: false,
                finished3: false,
                count3: 0,
                page3: 1,
                isload3: false,
                list4: [],
                // 无限加载
                loading4: false,
                finished4: false,
                count4: 0,
                page4: 1,
                isload4: false,
                keyword: '',
                wait: 0,
                execute: 0,
                finish:0,
                minDate: new Date(2020, 0, 1),
                defaultDate: new Date(),
                date:'',
                showCalendar:false,
                title:'理疗工作台',
                loading: false
            }
        },
        mounted () {
            this.$store.dispatch('changeIsClose', '1')
            this.$store.dispatch('changeNav', '0')
            this.$store.dispatch('hideOrShowNav', false)
            this.getCurDate()
            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.onLoad()
        },
        activated() {
            this.$store.dispatch('changeNav', '0')
            this.$store.dispatch('hideOrShowNav', false)
            console.log(this.isrefresh, '----isrefresh')
            if(this.isrefresh == '1' && !this.loading) {
                this.$store.dispatch('changeIsRefresh', '0')
                this.reLoad();
            }
        },
        beforeRouteLeave(to, from, next) {
            console.log(to,from,'treat-----')
            if(to.path == '/assignTreat'){
                from.meta.keepAlive = false
                
            }else if(to.path == '/triage'){
                to.meta.keepAlive = false
            }
            next()
        },
        methods: {
            goBack() {
                window.WeixinJSBridge.call('closeWindow')
            },
            getCurDate() {
                const nowDate = new Date();
                this.formatDate(nowDate)
            },
            addDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() + 1);
                this.formatDate(d)
                this.reLoad()
            },
            subtractDate() {
                var d = new Date(this.date);
                d.setDate(d.getDate() - 1);
                this.formatDate(d)
                this.reLoad()
            },
            formatDate(d) {
                const curDate = {
                    year: d.getFullYear(),
                    month: d.getMonth() + 1,
                    date: d.getDate(),
                }
                const newmonth = curDate.month>=10?curDate.month:'0'+curDate.month
                const day = curDate.date>=10?curDate.date:'0'+curDate.date
                this.date = curDate.year + '-' + newmonth + '-' + day
                this.defaultDate = new Date(this.date);
            },
            chooseCheckDay(){
                this.showCalendar = true
            },
            onConfirmCalendar(date){
                this.showCalendar = false
                this.formatDate(date);
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.reLoad()
            },

            changeTab(){
                if(this.qrystatus === '2' && this.isload2) {
                    return
                }
                if(this.qrystatus === '3' && this.isload3) {
                    return
                }
                if(this.qrystatus === '4' && this.isload4) {
                    return
                }
                Toast({
                    message: '加载中...',
                    type: 'loading',
                    duration: 0
                })
                this.onLoad()
            },
            reLoad(){
                this.finished2 = false
                this.loading2 = false
                this.count2 = 0
                this.page2 = 1
                this.list2 = []
                this.isload2 = false

                this.finished3 = false
                this.loading3 = false
                this.count3 = 0
                this.page3 = 1
                this.list3 = []
                this.isload3 = false

                this.finished4 = false
                this.loading4 = false
                this.count4 = 0
                this.page4 = 1
                this.list4 = []
                this.isload4 = false
                this.onLoad()
            },
            onLoad(){
                console.log(this.clinicid, '*****this.clinicid')
                if(this.clinicid == null || this.clinicid == '' || this.clinicid == undefined
                    || this.username == null || this.username == '' || this.username == undefined) {
                    Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '未登录或未选择诊所，页面关闭',
                    });
                    let second = 1;
                    const timer = setInterval(() => {
                        second--;
                        if (!second) {
                            clearInterval(timer);
                            // 手动清除 Toast
                            Toast.clear();
                            this.goBack()
                        }
                    }, 1000);
                    return;

                }
                this.loading = true
                if(this.qrystatus === '2' && this.finished2) {
                    return
                }
                if(this.qrystatus === '3' && this.finished3) {
                    return
                }
                if(this.qrystatus === '4' && this.finished4) {
                    return
                }

                let sdata = {
                    username: this.username,
                    qrydate: this.date,
                    keyword: this.keyword,
                    clinicid: this.clinicid
                }
                wxtreatstatistics(sdata).then(response => {
                    if(response.response_body){
                        let old_wait = this.wait
                        let old_execute = this.execute
                        let old_finish = this.finish
                        this.wait = parseInt(response.response_body.wait)
                        if(this.isload2 && this.wait != old_wait) {
                            this.finished2 = false
                            this.loading2 = false
                            this.count2 = 0
                            this.page2 = 1
                            this.list2 = []
                            this.isload2 = false
                        }
                        this.execute = parseInt(response.response_body.execute)
                        if(this.isload3 && this.execute != old_execute) {
                            this.finished3 = false
                            this.loading3 = false
                            this.count3 = 0
                            this.page3 = 1
                            this.list3 = []
                            this.isload3 = false
                        }
                        this.finish = parseInt(response.response_body.finish)
                        if(this.isload4 && this.finish != old_finish) {
                            this.finished4 = false
                            this.loading4 = false
                            this.count4 = 0
                            this.page4 = 1
                            this.list4 = []
                            this.isload4 = false
                        }
                    }
                    let qryPage = 1;
                    if(this.qrystatus === '2') {
                        qryPage = this.page2
                    }
                    if(this.qrystatus === '3') {
                        qryPage = this.page3
                    }
                    if(this.qrystatus === '4') {
                        qryPage = this.page4
                    }
                    let data = {
                        username: this.username,
                        qrydate: this.date,
                        keyword: this.keyword,
                        page: qryPage + '',
                        qrystatus: this.qrystatus,
                        clinicid: this.clinicid
                    }
                    wxtreatqry(data).then(response => {
                        if(Toast){
                            Toast.clear()
                        }
                        if(this.qrystatus === '2') {
                            this.isload2 = true
                        }
                        if(this.qrystatus === '3') {
                            this.isload3 = true
                        }
                        if(this.qrystatus === '4') {
                            this.isload4 = true
                        }
                        if(response.response_body && response.response_body.list){
                            if(this.qrystatus === '2') {
                                this.count2 = parseInt(response.response_body.num)
                                this.list2 = this.list2.concat(response.response_body.list)
                                this.finished2 = (this.page2 >= response.response_body.pages);
                                this.page2 = parseInt(this.page2)+1
                            }
                            if(this.qrystatus === '3') {
                                this.count3 = parseInt(response.response_body.num)
                                this.list3 = this.list3.concat(response.response_body.list)
                                this.finished3 = (this.page3 >= response.response_body.pages);
                                this.page3 = parseInt(this.page3)+1
                            }
                            if(this.qrystatus === '4') {
                                this.count4 = parseInt(response.response_body.num)
                                this.list4 = this.list4.concat(response.response_body.list)
                                this.finished4 = (this.page4 >= response.response_body.pages);
                                this.page4 = parseInt(this.page4)+1
                            }

                        }
                        if(this.qrystatus === '2') {
                            // 加载状态结束
                            this.loading2 = false;
                        }
                        if(this.qrystatus === '3') {
                            // 加载状态结束
                            this.loading3 = false;
                        }
                        if(this.qrystatus === '4') {
                            // 加载状态结束
                            this.loading4 = false;
                        }
                        this.loading = false
                    })
                })


            },

            // 理疗详情
            detailCtrl(serviceid){
                this.$store.dispatch('changeIsRefresh', '0')
                this.$router.push({
                    path: '/treatDetail',
                    query: {
                        serviceid: serviceid
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .van-info{
        top: 2px;
        right: -8px;
    }
    .van-cell{
        background-color: #F8F7FC;
        border-radius: 0.50667rem;
    }
    .bai {
        background-color: #ffffff;
    }
    .btnimg{
        width: 50px;
        height: 50px;
        position: fixed;
        right: 10px;
        bottom: 200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .treat_main_list .one{
        background: linear-gradient( 180deg, rgba(207,140,87,0.2) 0%, rgba(255,249,244,0) 20%);
    }
</style>